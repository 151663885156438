import React, { useState } from "react";

export default function Textform(props) {
  const handleUpClick = () => {
    let newText = text.toUpperCase();
    setText(newText);
    props.showAlert("Converted To Uppercase!","success");
  };
  const handleLoClick = () => {
    let newText = text.toLowerCase();
    setText(newText);
    props.showAlert("Converted To Lowercase!","success");
  };
  const handleClearClick = () => {
    let newText = "";
    setText(newText);
    props.showAlert("All Text Clear!","success");
  };

  const handleCopy = ()=>{
    let text = document.getElementById('myBox');
    text.select();
    navigator.clipboard.writeText(text.value);
    document.getSelection().removeAllRanges();
    props.showAlert("Copied To Clipboard!","success");
  };

  const handleExtraSpaces = ()=> {
      let newText = text.split(/[ ] +/);
      setText(newText.join(" "))
      props.showAlert("Clear Extra Spaces!","success");
  };

  const handleOnChange = (event) => {
    setText(event.target.value);
  };
  const [text, setText] = useState("");
  return (
      <>
    <div className="container" style={{color:props.mode==='dark'?'white':'black'}}>
      <h1 className="mb-4">{props.heading}</h1>
      <div className="mb-3">
        <textarea className="form-control" value={text} onChange={handleOnChange} 
        style={{backgroundColor:props.mode==='dark'?'#13466e':'white',color:props.mode==='dark'?'white':'black'}} 
        id="myBox" rows="8" placeholder="Enter Text Here"></textarea>
      </div>
      <button disabled={text.length==0} className="btn btn-primary mx-2" onClick={handleUpClick}>Convert To Uppercase</button>
      <button disabled={text.length==0} className="btn btn-primary mx-2" onClick={handleLoClick}>Convert To Lowercase</button>
      <button disabled={text.length==0} className="btn btn-primary mx-2" onClick={handleCopy}>Copy All Text</button>
      <button disabled={text.length==0} className="btn btn-primary mx-2" onClick={handleClearClick}>Clear All Text</button>
      <button disabled={text.length==0} className="btn btn-primary mx-2" onClick={handleExtraSpaces}>Clear Extra Spaces</button>
    </div>
    <div className="container my-3" style={{color:props.mode==='dark'?'white':'black'}}>
        <h1>Your Text Summary</h1>
        <p>{text.split(/\s+/).filter((element)=>{return element.length!==0}).length} words and {text.length} charachters</p>
        <p>{0.008 * text.split(/\s+/).filter((element)=>{return element.length!==0}).length} Minutes Read</p>
        <h2>Preview</h2>
        <p>{text.length>0?text:"Enter Something In Textbox To Preview Here"}</p>
    </div>
    </>
  );
}
