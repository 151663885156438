import { useState } from 'react';
import './App.css';
import About from './Components/About';
import Alert from './Components/Alert';
import Navbar from './Components/Navbar';
import Textform from './Components/Textform';

import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

function App() {
  const [mode, setMode] = useState('light');
  const [alert, setAlert] = useState(null);

  const showAlert = (message,type)=>{
    setAlert({
      msg: message,
      type: type
    })
    setTimeout(() => {
      setAlert(null)
    }, 2000);
  }
  const toggleMode = ()=>{
    if(mode === 'light'){
      setMode('dark');
      document.body.style.backgroundColor = "#042743";
      showAlert("Dark mode is enabled!", "success");
      document.title = "My App - Dark Mode"
      // setInterval(() => {
      //   document.title = "My App - My App Is Amazing"
      // }, 2000);
      // setInterval(() => {
      //   document.title = "My App - Install My App"
      // }, 1500);
    }
    else{
      setMode('light');
      document.body.style.backgroundColor = "white";
      showAlert("Light mode is enabled!", "success");
      document.title = "My App - Light Mode"
    }
  }
  return (
    <>
{/* <Navbar title="My App" aboutText="About Us"/> */}
{/* <Navbar/> */}
<Router>
<Navbar title="My App" mode={mode} toggleMode={toggleMode}/>
<Alert alert={alert}/>
<div className="container my-3">
      <Routes>
          <Route exact path="/About" element={<About mode={mode}/>} >
          </Route>
          <Route exact path="/" element={<Textform showAlert={showAlert} heading="Enter the text to analyze" mode={mode}/>}>
          </Route>
      </Routes>
</div>
</Router>
    </>
  );
}

export default App;
